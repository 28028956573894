<template>
    <div>
        <div class="alert alert-danger" v-if="errors">
            <div class="font-bold">
                {{ $t('toastr.error') }}
            </div>
            <ul>
                <template v-for="errorGroup in errors">
                    <li :key="index" v-for="(error, index) in errorGroup">
                        {{ error }}
                    </li>
                </template>
            </ul>
        </div>

        <div class="mb-24 space-y-6">
            <div class="box" :class="{ loading: loadingTemplate }">
                <div
                    class="grid gap-6 p-6 grid-cols-2"
                >
                    <div>
                        <activix-label :label="$t(`templates.templateTitle.${template.type}`)" />
                        <activix-tooltip :content="$t('templates.templateTitle.tooltip')" v-if="isNotification">
                            <icon name="regular/information-circle" class="text-grey-500 text-sm ml-1" />
                        </activix-tooltip>
                        <activix-input v-model="template.title" />
                    </div>
                    <div v-if="!isNotification">
                        <activix-label :label="$t('templates.category')" />
                        <activix-select
                            :options="[]"
                            :placeholder="$t('multiselect.selectOption')"
                            v-model="template.division"
                        >
                            <el-option
                                :label="tmpDivision.name"
                                :value="tmpDivision.id"
                                :key="tmpDivision.id"
                                v-for="tmpDivision in divisions"
                            />
                        </activix-select>
                    </div>
                </div>
            </div>

            <div class="box" :class="{ loading: loadingTemplate }" v-if="isEmail">
                <div class="grid grid-cols-2 gap-6 p-6">
                    <div>
                        <activix-label :label="$t('options.cc')" />
                        <div class="flex rounded-sm | group">
                            <activix-select
                                class="w-full | p-0"
                                :allow-create="true"
                                :default-first-option="true"
                                :filterable="true"
                                :multiple="true"
                                :no-data-text="$t('mailClient.enterCcRecipients')"
                                :options="[]"
                                :placeholder="$t('options.defaultCc')"
                                :value="template.cc"
                                @change="template.cc = $event"
                            />
                            <div class="flex flex-shrink-0 items-center px-3 space-x-2 rounded-r-sm bg-gray-50">
                                <activix-tooltip :content="$t('templates.assignedInfoCc')">
                                    <label class="flex items-center m-0 text-gray-600 font-normal cursor-help" for="assignedCc">
                                        <activix-checkbox
                                            id="assignedCc"
                                            class="mr-1"
                                            :disabled="!template.cc.length"
                                            v-model="template.ccAssigned"
                                        />
                                        {{ $t('templates.assignedOnly') }}
                                    </label>
                                </activix-tooltip>
                            </div>
                        </div>
                    </div>
                    <div>
                        <activix-label :label="$t('options.bcc')" />
                        <div class="flex p-0 rounded-sm | group">
                            <activix-select
                                class="w-full | p-0"
                                :allow-create="true"
                                :default-first-option="true"
                                :filterable="true"
                                :multiple="true"
                                :no-data-text="$t('mailClient.enterBccRecipients')"
                                :options="[]"
                                :placeholder="$t('options.defaultBcc')"
                                :value="template.bcc"
                                @change="template.bcc = $event"
                            />
                            <div class="flex flex-shrink-0 items-center px-3 space-x-2 rounded-r-sm bg-gray-50">
                                <activix-tooltip :content="$t('templates.assignedInfoBcc')">
                                    <label class="flex items-center m-0 text-gray-600 font-normal cursor-help" for="assignedBcc">
                                        <activix-checkbox
                                            id="assignedBcc"
                                            class="mr-1"
                                            :disabled="!template.bcc.length"
                                            v-model="template.bccAssigned"
                                        />
                                        {{ $t('templates.assignedOnly') }}
                                    </label>
                                </activix-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="relative flex-1 flex flex-col">
                <activix-tabs
                    tab-width-class-name="w-48"
                    :tabs="tabs"
                    text-alignment="left"
                    v-model="activeTab"
                    v-if="activeTab"
                >
                    <template #after>
                        <activix-tooltip
                            :content="$t('templates.copyTemplate', [$t(`templates.locales.${translationAvailable[0]}.name`)])"
                            v-if="!!translationAvailable.length"
                        >
                            <button class="flex items-center text-blue-500 hover:text-blue-600" @click="addTranslation(translationAvailable[0], activeTab)">
                                <icon class="text-sm" :name="$icons.add" />
                            </button>
                        </activix-tooltip>
                    </template>
                    <template #moreButtons>
                        <el-dropdown
                            trigger="click"
                            class="flex items-center"
                            @click.native.stop
                            v-if="template.translations.length > 1"
                        >
                            <button class="flex items-center justify-center ml-1 h-7 w-7 hover:bg-gray-200 rounded-full">
                                <icon class="text-grey-500" name="bold/navigation-menu-vertical" />
                            </button>

                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="askDeleteTemplate(activeTab)">
                                    {{ $t('templates.delete') }}
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </activix-tabs>

                <activix-tooltip :content="template.is_advanced ? $t('templates.goToBasic') : $t('templates.goToAdvanced')" v-if="!!activeTab && !isNotification">
                    <button class="absolute right-0 top-4 flex justify-center items-center p-2 rounded-sm text-xl text-gray-600 | hover:bg-gray-300" @click="askToggleAdvanced">
                        <icon name="regular/synchronize-arrows-square-1" />
                    </button>
                </activix-tooltip>

                <template-translate
                    :template-type="template.type"
                    :class="{ loading: loadingTemplate }"
                    :translation-data="translation"
                    :is-advanced="template.is_advanced"
                    :key="translation.locale"
                    @update="translation = $event"
                    v-for="translation in template.translations"
                    v-show="translation.locale == activeTab"
                />
                <template-empty
                    :template-type="template.type"
                    @set-advanced="setAdvanced($event)"
                    v-if="!activeTab && !loadingTemplate"
                />
            </div>

            <div class="fixed inset-x-0 bottom-0 z-30 ml-0 p-6 flex justify-end items-end border-t border-gray-250 shadow-t-md bg-white">
                <template-buttons
                    :loading="$wait.is('fetching.template')"
                    :saving="$wait.is(['creating.template', 'updating.template', 'saving.template'])"
                    @save="onSave"
                    @cancel="onCancel"
                />
            </div>
        </div>

        <activix-confirm-modal
            type="warning"
            :content="$t('templates.doYouWantToSave')"
            :opened.sync="modals.beforeLeaveWarning"
            @deny="nextPage"
            @approve="saveBeforeLeave"
        />

        <activix-confirm-modal
            type="warning"
            :content="$t('templates.toogleAdvanced')"
            :opened.sync="modals.askToggleAdvanced.opened"
            @approve="toggleAdvanced"
        />

        <activix-confirm-modal
            type="danger"
            :content="$t('templates.askDeleteTemplate')"
            :opened.sync="modals.askDeleteTemplate.opened"
            @approve="deleteTemplate(modals.askDeleteTemplate.locale)"
        />
    </div>
</template>

<script>
    import { forEach, orderBy } from 'lodash-es';

    import { mapActions, mapState } from 'pinia';

    import ActivixLabel from '@/components/elements/ActivixLabel.vue';
    import TemplateButtons from '@/components/templates/TemplateButtons.vue';
    import TemplateEmpty from '@/components/templates/TemplateEmpty.vue';
    import TemplateTranslate from '@/components/templates/TemplateTranslate.vue';
    import TemplateType from '@/entities/TemplateType';
    import { useGlobalStore } from '../../store/store.js';
    import { useContextStore } from '../../store/modules/context/store.js';

    import { showWarning } from '../../utils/toastr.js';
    import TrackView from '../../mixins/TrackView.js';
    import Division from '../../entities/Division.js';

    export default {
        name: 'TemplatesEdit',

        components: {
            TemplateTranslate,
            TemplateEmpty,
            ActivixLabel,
            TemplateButtons,
        },

        mixins: [TrackView],

        data() {
            return {
                activeTab: null,
                localeAvailable: ['en', 'fr'],
                errors: null,
                modals: {
                    beforeLeaveWarning: false,
                    askToggleAdvanced: {
                        opened: false,
                    },
                    askDeleteTemplate: {
                        opened: false,
                        locale: '',
                    },
                },
                nextPage: () => {},
                edited: false,
                template: {
                    type: this.$route.params.type ?? TemplateType.EMAIL,
                    is_advanced: true,
                    title: '',
                    division: null,
                    cc: [],
                    bcc: [],
                    ccAssigned: false,
                    bccAssigned: false,
                    attachments: [],
                    translations: [],
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, {
                attachmentParameters: 'attachmentParameters',
                authUser: 'authUser',
            }),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            tabs() {
                return this.template.translations.map(translation => {
                    return {
                        name: translation.locale,
                        label: this.$t(`templates.locales.${translation.locale}.name`),
                        subLabel: this.$t(`templates.locales.${translation.locale}.realName`),
                    };
                }).sort((a) => (this.contextAccount.locale === 'fr' && a.name === 'fr' ? -1 : 1));
            },

            templateId() {
                return this.$route.params.id;
            },

            getUrlParamType() {
                if (!TemplateType.values.includes(this.$route.params.type)) {
                    return TemplateType.EMAIL;
                }
                return this.$route.params.type;
            },

            templateType() {
                if (this.template.id) {
                    return this.template.type;
                }

                return this.getUrlParamType;
            },

            isEmail() {
                if (!this.automationNotificationEnabled) {
                    return true;
                }

                return this.template.type === TemplateType.EMAIL;
            },

            isNotification() {
                if (!this.automationNotificationEnabled) {
                    return false;
                }

                return this.template.type === TemplateType.NOTIFICATION;
            },

            automationNotificationEnabled() {
                return this.$feature.isEnabled('automation-notification');
            },

            divisions() {
                const divisions = [
                    { id: Division.NEW, name: this.$t('divisions.new') },
                    { id: Division.USED, name: this.$t('divisions.used') },
                    { id: Division.GENERAL, name: this.$t('divisions.general') },
                    { id: Division.REVIVAL, name: this.$t('divisions.revival') },
                    { id: Division.FOLLOW_UP, name: this.$t('divisions.followUp') },
                    { id: Division.FINANCE, name: this.$t('divisions.finance') },
                    { id: Division.BDC, name: this.$t('divisions.bdc') },
                ];

                if (this.contextAccount.service) {
                    divisions.push({ id: Division.SERVICE, name: this.$t('divisions.service') });
                }

                return orderBy(divisions, 'name');
            },

            translationAvailable() {
                const localeUsed = this.template.translations.map(translation => translation.locale);

                return this.localeAvailable.filter(locale => !localeUsed.includes(locale));
            },

            loadingTemplate() {
                return this.$wait.is(['fetching.template', 'creating.template', 'updating.template']);
            },
        },

        watch: {
            templateId: {
                immediate: true,
                handler() {
                    this.fetchTemplate();
                },
            },

            cc(newValue) {
                if (!newValue.length) {
                    this.template.ccAssigned = false;
                }
            },

            bcc(newValue) {
                if (!newValue.length) {
                    this.template.bccAssigned = false;
                }
            },

            title(newValue, oldValue) {
                if (newValue != oldValue && oldValue) {
                    this.edited = true;
                }
            },

            'template.translations': {
                deep: true,
                handler() {
                    this.edited = true;
                },
            },
        },

        methods: {
            ...mapActions(useContextStore, ['setContextAccountAction', 'reloadContextAccount']),

            askDeleteTemplate(locale) {
                this.modals.askDeleteTemplate.locale = locale;
                this.modals.askDeleteTemplate.opened = true;
            },

            deleteTemplate(locale) {
                this.template.translations = this.template.translations.filter(translation => translation.locale != locale);
            },

            addTranslation(locale, fromLocale) {
                if (this.translationAvailable.length == 0) {
                    return;
                }

                let toLocale = this.$i18n.locale;

                if (this.translationAvailable.length == 1) {
                    toLocale = this.translationAvailable[0];
                }

                if (locale) {
                    toLocale = locale;
                }

                this.template.translations.push(this.buildTranslation(toLocale, fromLocale));
                this.activeTab = toLocale;
            },

            buildTranslation(locale, fromLocale) {
                const fromTranslation = this.template.translations.find(translation => translation.locale == fromLocale);

                return { locale, subject: '', design: fromTranslation?.design, body: fromTranslation?.body, excerpt: fromTranslation?.excerpt, response_template_attachments: [] };
            },

            onCancel() {
                this.edited = false;
                this.$router.push({ name: 'templates.index' });
            },

            onSave() {
                this.errors = null;
                const payload = new FormData();

                payload.append('account', this.contextAccount.id);
                payload.append('category', this.template.division);
                payload.append('title', this.template.title);
                payload.append('type', this.template.type);
                payload.append('is_advanced', this.template.is_advanced);
                payload.append('ccAssigned', this.template.ccAssigned);
                payload.append('bccAssigned', this.template.bccAssigned);
                payload.append('translations', JSON.stringify(this.template.translations));

                this.template.cc.forEach(element => {
                    payload.append('cc[]', element);
                });

                this.template.bcc.forEach(element => {
                    payload.append('bcc[]', element);
                });

                // Add Attachments
                this.template.translations.forEach(translation => {
                    translation.response_template_attachments.forEach(attachment => {
                        if (attachment.id) {
                            payload.append(`existingAttachments_${translation.locale}[]`, JSON.stringify(attachment));
                        } else {
                            payload.append(`attachments_${translation.locale}[]`, attachment);
                        }
                    });
                });

                this.edited = false;

                if (!this.templateId) {
                    this.createTemplate(payload);
                } else {
                    this.updateTemplate(payload);
                }
            },

            async createTemplate(payload) {
                this.$wait.start('creating.template');

                try {
                    const response = await this.$axios.post('v1/templates', payload);

                    this.reloadContextAccount();

                    this.$wait.end('creating.template');
                    this.$notify.success(this.$t('templates.alerts.store.success'));
                    this.$router.push({ name: 'templates.update', params: { id: response.data.data.id } });
                } catch (error) {
                    this.$wait.end('creating.template');

                    if (error.response) {
                        switch (error.response.status) {
                            case 400:
                                showWarning(this.$t('emailLead.invalidEmail', [error.response.data.message]));
                                return;
                            case 422:
                                this.errors = error.response.data.errors;
                                return;
                        }
                    }

                    this.$notify.error(this.$t('templates.alerts.store.error'));

                    throw error;
                }
            },

            async updateTemplate(payload) {
                this.$wait.start('updating.template');

                payload.append('_method', 'PUT'); // Required since put request can't send FormData.

                try {
                    await this.$axios.post(`v1/templates/${this.templateId}`, payload);

                    this.reloadContextAccount();

                    this.$wait.end('updating.template');
                    this.$notify.success(this.$t('templates.alerts.update.success'));
                } catch (error) {
                    this.$wait.end('updating.template');

                    if (error.response) {
                        switch (error.response.status) {
                            case 400:
                                showWarning(this.$t('emailLead.invalidEmail', [error.response.data.message]));
                                return;
                            case 422:
                                this.errors = error.response.data.errors;
                                return;
                        }
                    }

                    this.$notify.error(this.$t('templates.alerts.update.error'));

                    throw error;
                }
            },

            async fetchTemplate() {
                if (!this.templateId) {
                    return;
                }

                this.$wait.start('fetching.template');

                try {
                    const response = await this.$axios.get(`v1/templates/${this.templateId}`);

                    this.$wait.end('fetching.template');
                    this.setValues(response.data.data);
                } catch (error) {
                    this.$wait.end('fetching.template');
                    this.$notify.error(this.$t('templates.alerts.show.error'));

                    throw error;
                }
            },

            async saveBeforeLeave() {
                await this.onSave();

                this.nextPage();
            },

            setValues(template) {
                if (!template) {
                    template = {
                        division_id: null,
                        mass_mailing: false,
                        response_template_translations: [],
                    };
                }

                if (template.account_id) {
                    this.setContextAccountAction(template.account_id);
                }

                this.template.is_advanced = template.is_advanced;
                this.template.title = template.title;
                this.template.type = template.type;
                this.template.division = template.division_id;
                this.template.cc = template.cc || [];
                this.template.bcc = template.bcc || [];
                this.template.ccAssigned = template.cc_assigned || false;
                this.template.bccAssigned = template.bcc_assigned || false;

                this.template.translations = template.response_template_translations;

                if (this.tabs.length) {
                    this.activeTab = this.tabs[0].name;
                }

                if (!template.is_advanced && !template.title) {
                    let templateTranslate = template.response_template_translations.find(translation => translation.locale == this.$i18n.locale);

                    if (!templateTranslate) {
                        templateTranslate = template.response_template_translations.find(translation => !empty(`${translation.title}`.trim()));
                    }

                    this.template.title = templateTranslate?.title;
                }
            },

            setAdvanced(advanced) {
                this.template.is_advanced = advanced;
                this.addTranslation();
            },

            askToggleAdvanced() {
                this.modals.askToggleAdvanced.opened = true;
            },

            toggleAdvanced() {
                forEach(this.template.translations, (translation) => {
                    translation.body = null;
                    translation.design = null;
                });

                this.template.is_advanced = !this.template.is_advanced;
            },
        },

        beforeRouteLeave(to, from, next) {
            if (this.edited) {
                this.modals.beforeLeaveWarning = true;
                this.nextPage = next;
            } else {
                next();
            }
        },

        mounted() {
            if (this.isNotification) {
                this.template.division = 6;
            }
        },
    };
</script>
