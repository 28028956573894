<template>
    <activix-accordion class="box-group" :items="templateAttributes">
        <template slot="header" slot-scope="{ item }">
            {{ normalizeTagText(item.name) }}
        </template>
        <template slot-scope="{ item }">
            <div
                class="btn btn-default | block py-1 px-2 my-2 mx-0 truncate shadow-none"
                :key="index"
                @click="$emit('click', tag)"
                v-text="normalizeTagText(tag.text)"
                v-for="(tag, index) in item.tags"
            />
        </template>
    </activix-accordion>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store.js';

    export default {
        computed: {
            ...mapState(useGlobalStore, {
                templateAttributes: store => store.templateAttributes,
            }),
        },

        methods: {
            normalizeTagText(content) {
                if (content.includes('&amp;')) {
                    return content.replace(/&amp;/g, '&');
                }

                return content;
            },
        },
    };
</script>
