<template>
    <div class="w-full rounded shadow-lg bg-white p-6">
        <div class="relative flex flex-col justify-center items-center h-0 min-h-md max-h-screen-75 border border-gray-300 rounded-sm bg-gray-200">
            <h4 class="mb-6 text-gray-700">
                {{ $t('templates.empty.choose') }}
            </h4>

            <div class="max-w-sm space-y-6">
                <div class="flex items-center justify-center p-6 border border-gray-300 rounded-sm text-gray-600 bg-white | cursor-pointer transform transition hover:shadow-xl hover:scale-105" @click="setAdvanced(false)">
                    <icon class="flex-shrink-0 mr-6 text-5xl" name="regular/font-size" />
                    <div>
                        <p class="mb-1 text-xl leading-none" v-text="$t('templates.empty.basic')" />
                        <p class="m-0 text-sm text-justify" v-html="$t('templates.empty.basicDescription')" />
                    </div>
                </div>

                <div class="flex items-center justify-center p-6 border border-gray-300 rounded-sm text-gray-600 bg-white | cursor-pointer transform transition hover:shadow-xl hover:scale-105" @click="setAdvanced(true)">
                    <icon class="flex-shrink-0 mr-6 text-5xl" name="regular/color-painting-palette" />
                    <div>
                        <p class="mb-1 text-xl leading-none" v-text="$t('templates.empty.advanced')" />
                        <p class="m-0 text-sm text-justify" v-html="$t('templates.empty.advancedDescription')" />
                    </div>
                </div>
            </div>

            <div class="flex items-center mt-4 p-4 text-gray-500">
                <icon class="mr-1" name="bold/alert-triangle" />
                {{ $t('templates.empty.alert') }}
            </div>
        </div>
    </div>
</template>

<script>
    import TemplateType from '@/entities/TemplateType';

    export default {
        props: {
            templateType: {
                type: String,
                required: true,
                default: TemplateType.EMAIL,
            },
        },

        computed: {
            automationNotificationEnabled() {
                return this.$feature.isEnabled('automation-notification');
            },

            isEmail() {
                if (!this.automationNotificationEnabled) {
                    return true;
                }
                return this.templateType === TemplateType.EMAIL;
            },

            isNotification() {
                if (!this.automationNotificationEnabled) {
                    return false;
                }
                return this.templateType === TemplateType.NOTIFICATION;
            },
        },

        watch: {
            templateType: {
                immediate: true,
                handler() {
                    if (this.isNotification) {
                        this.setAdvanced(false);
                    }
                },
            },
        },

        methods: {
            setAdvanced(advanced) {
                this.$emit('set-advanced', advanced);
            },
        },
    };
</script>
