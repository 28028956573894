<template>
    <div
        class="w-full rounded bg-white"
        :class="{ 'shadow-lg': isAdvanced }"
    >
        <div :class="{'flex bg-gray-200': !isAdvanced}">
            <div :class="{'col-xs-7 col-md-9 | flex flex-col px-0 rounded bg-white shadow-lg': !isAdvanced}">
                <div class="p-6 grid grid-cols-1 gap-6">
                    <div>
                        <activix-label :label="$t(`templates.subject.${templateType}`)" />
                        <activix-input ref="subject" v-model="translation.subject" />
                    </div>

                    <div>
                        <div class="flex justify-between items-end">
                            <activix-label :label="$t(`templates.body.${templateType}`)" />

                            <div class="flex justify-end items-center pb-2 space-x-2">
                                <activix-tooltip :content="$t('templates.fullscreen')" v-if="isAdvanced">
                                    <button class="flex justify-center items-center p-2 rounded-sm text-xl text-gray-600 | hover:bg-gray-300" @click="fullScreen">
                                        <icon name="regular/expand-3" />
                                    </button>
                                </activix-tooltip>
                            </div>
                        </div>
                        <template-editor
                            :locale="translation.locale"
                            :design="translation.design"
                            ref="templateEditor"
                            @update-design="translation.design = $event"
                            @update-body="translation.body = $event"
                            v-if="isAdvanced"
                        />

                        <activix-summernote
                            ref="summernote"
                            v-model="translation.body"
                            v-else
                        />
                    </div>
                    <div v-if="isNotification()">
                        <activix-label :label="$t('templates.excerpt')" />
                        <activix-input :maxlength="70" ref="excerpt" v-model="translation.excerpt" />
                    </div>
                </div>
                <div class="pb-10 px-6" v-if="isEmail()">
                    <activix-label :label="$t('templates.attachments')" />
                    <activix-drag-upload
                        class="min-h-48"
                        :is-advanced="isAdvanced"
                        v-model="translation.response_template_attachments"
                    />
                </div>
                <div class="p-6" :class="{ loading: $wait.is('fetching.template') }" v-if="!isAdvanced">
                    <div class="flex flex-col 2xl:flex-row w-full justify-between">
                        <div class="flex flex-col w-full">
                            <activix-label :label="$t('templates.preview.email')" class="text-sm lg:text-base" />
                            <div class="panel panel-default | flex-grow mb-0">
                                <div class="panel-heading">
                                    <activix-tooltip :content="$t('automations.actions.sendNotification.templateTooltip')">
                                        <span v-html="subjectPreview" />
                                    </activix-tooltip>
                                </div>
                                <div class="panel-body">
                                    <span v-html="bodyPreview" />
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col 2xl:mt-0 2xl:px-4" :class="{'mt-6': translation.body}" v-if="translation.excerpt">
                            <activix-label :label="$t('templates.preview.notification')" class="text-sm xl:text-base" />
                            <div class="notification-wrapper">
                                <div class="mr-1">
                                    <activix-tooltip :content="$t('notificationCenter.general.automationNotification', this.translation.locale)">
                                        <icon class="w-6 h-6 text-grey-600" name="regular/video-game-magic-wand" />
                                    </activix-tooltip>
                                </div>
                                <div class="flex-1 px-4 overflow-hidden">
                                    <div class="subject | font-bold">
                                        {{ subjectPreview }}
                                    </div>
                                    <activix-tooltip :content="excerptPreview">
                                        <div class="subject" v-html="excerptPreview" />
                                    </activix-tooltip>
                                    <div class="subject">
                                        {{ $t('notificationCenter.lead') }} :
                                        <router-link class="link-blue" to="#">
                                            {{ $t('templates.leadName') }}
                                        </router-link>
                                    </div>
                                </div>
                                <div class="flex flex-col text-right text-sm text-grey-600">
                                    <div>{{ creationDate }}</div>
                                    <div>{{ creationTime }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xs-5 col-md-3 | pr-0 pl-6 rounded" ref="attributes" v-show="!isAdvanced">
                <div class="box | h-full" :class="{ loading: $wait.is('fetching.template') }">
                    <div class="box-header | border-b">
                        <h4 class="box-title">
                            {{ $t('templates.attributsList') }}
                        </h4>
                    </div>

                    <div class="box-body">
                        <template-attributes @click="addTag" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store.js';
    import ActivixDragUpload from '@/components/elements/ActivixDragUpload.vue';
    import TemplateEditor from '@/components/templates/TemplateEditor.vue';
    import ActivixLabel from '@/components/elements/ActivixLabel.vue';
    import TemplateType from '@/entities/TemplateType';
    import TemplateAttributes from './TemplateAttributes.vue';
    import { insertAtCaret } from '../../utils/caret.js';
    import ActivixDate from '../../value-objects/ActivixDate.js';

    export default {
        components: {
            ActivixDragUpload,
            TemplateAttributes,
            TemplateEditor,
            ActivixLabel,
        },

        props: {
            templateType: {
                type: String,
                required: true,
            },
            translationData: {
                type: Object,
                required: true,
            },
            isAdvanced: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                translation: this.translationData,
            };
        },

        computed: {
            ...mapState(useGlobalStore, {
                templateAttributes: store => store.templateAttributes,
            }),

            subjectPreview() {
                let subject = 'N/A';
                if (this.translation.subject) {
                    subject = this.convertAttributesToContent(this.translation.subject, this.translation.locale);
                }

                return subject;
            },

            excerptPreview() {
                let excerpt = 'N/A';
                if (this.translation.excerpt) {
                    excerpt = this.convertAttributesToContent(this.translation.excerpt, this.translation.locale);
                }

                return excerpt;
            },

            bodyPreview() {
                let body = 'N/A';
                if (this.translation.body) {
                    body = this.convertAttributesToContent(this.translation.body, this.translation.locale);
                }

                return body;
            },

            creationDate() {
                return new ActivixDate(new Date()).toHumanShort(false);
            },

            creationTime() {
                return new ActivixDate(new Date()).toHumanTime();
            },
        },

        watch: {
            translationData() {
                this.translation = this.translationData;
            },

            translation() {
                this.$emit('update', this.translation);
            },
        },

        methods: {
            fullScreen() {
                if (this.$refs.templateEditor) {
                    this.$refs.templateEditor.$el.requestFullscreen();
                }
            },

            convertAttributesToContent(content, locale) {
                content = this.normalizeTagText(content);

                this.templateAttributes.forEach(category => {
                    category.tags.forEach(tag => {
                        const placeholderContent = tag[`placeholder-${locale}`];
                        const attributeFr = this.normalizeTagText(`{${category.name_fr} - ${tag.text_fr}}`);
                        const attributeEn = this.normalizeTagText(`{${category.name_en} - ${tag.text_en}}`);

                        content = content.split(attributeFr).join(placeholderContent);
                        content = content.split(attributeEn).join(placeholderContent);
                    });
                });

                return content;
            },

            addTag(tag) {
                const element = document.activeElement;
                const summernoteParentElement = element.parentNode.parentNode.parentNode;
                const categoryName = this.getTagCategory(tag).name;
                const text = this.normalizeTagText(`{${categoryName} - ${tag.text}}`);

                if (!element) {
                    return;
                }

                // Subject
                if (this.$refs.subject.$el.isSameNode(element.parentNode)) {
                    insertAtCaret(element, text);
                }

                // Excerpt
                if (this.$refs.excerpt && this.$refs.excerpt.$el.isSameNode(element.parentNode)) {
                    insertAtCaret(element, text);
                }

                // Body
                if (this.$refs.summernote.$el.isSameNode(summernoteParentElement)) {
                    const node = document.createElement('span');
                    const textNode = document.createTextNode(text);

                    node.appendChild(textNode);

                    this.$refs.summernote.insertNode(node);
                }
            },

            getTagCategory(tag) {
                const category = this.templateAttributes.find(category => {
                    const tagInCategory = category.tags.some(t => t.tag == tag.tag);

                    return tagInCategory;
                });

                return category || {};
            },

            normalizeTagText(content) {
                if (content.includes('&amp;')) {
                    return content.replace(/&amp;/g, '&');
                }

                return content;
            },

            isEmail() {
                return this.templateType === TemplateType.EMAIL;
            },

            isNotification() {
                return this.templateType === TemplateType.NOTIFICATION;
            },
        },

        mounted() {
            // Prevent lost of focus on attributes click
            this.$refs.attributes.addEventListener('mousedown', e => {
                e.preventDefault();
            });
        },
    };
</script>

<style lang="less">
.notification-wrapper {
    @apply relative whitespace-nowrap border border-grey-200 p-4 flex items-center;

    width: 363px;

    .subject {
        @apply truncate text-sm text-grey-600;
    }
}
</style>
