<template>
    <div>
        <activix-button class="mr-1" :disabled="loading" @click="onCancel">
            {{ $t('general.cancel') }}
        </activix-button>

        <activix-button
            class="ml-2"
            type="primary"
            :disabled="loading"
            :loading="saving"
            @click="onSave"
        >
            {{ $t('general.save') }}
        </activix-button>
    </div>
</template>

<script>
    export default {
        props: {
            loading: {
                type: Boolean,
                default: false,
            },
            saving: {
                type: Boolean,
                default: false,
            },
        },

        methods: {
            onCancel() {
                this.$emit('cancel');
            },

            onSave() {
                this.$emit('save');
            },
        },
    };
</script>
